import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import PostList from '../components/Posts/PostList';
import CategoryFilter from '../components/Posts/CategoryFilter';
import { TextHeader } from '../acf/TextHeader/TextHeader.jsx';
import { FormBlock } from '../acf/FormBlock/FormBlock.jsx';
import { decodeEntities } from '../utils/htmlParse';

export default class BlogPage extends React.Component {
  render() {
    const { data, pageContext, location } = this.props;
    const { site, allWordpressPost, blogPageComponents } = data;
    if (!allWordpressPost || !blogPageComponents) return null;
    const {
      childWordPressAcfTextHeader = null,
      childWordPressAcfFormBlock = null,
    } = blogPageComponents;
    const {
      title: siteTitle,
      blogSlug
    } = site.siteMetadata;
    const { edges: posts } = allWordpressPost;
    return (
      <Layout location={location}>
        <SEO title={`Blog | ${decodeEntities(siteTitle)}`} />
        {childWordPressAcfTextHeader && (
          <TextHeader
            smallHeading={childWordPressAcfTextHeader.smallHeading}
            heading={childWordPressAcfTextHeader.heading}
          />
        )}
        <CategoryFilter pathPrefix={`${blogSlug}`} location={location} />
        <PostList
          posts={posts}
          blogSlug={blogSlug}
          pathPrefix={blogSlug}
          pageContext={pageContext}
          siteMetadata={site.siteMetadata}
          location={location}
        />
        {childWordPressAcfFormBlock && (
          <FormBlock
            smallHeading={childWordPressAcfFormBlock.smallHeading}
            heading={childWordPressAcfFormBlock.heading}
            form={childWordPressAcfFormBlock.form}
            location={location}
          />
        )}
      </Layout>
    )
  }
}

export const pageQuery = graphql`
  query BlogQuery($limit: Int!, $skip: Int!) {
    site {
      siteMetadata {
        title
        blogSlug
      }
    }
    blogPageComponents: wordpressPage(slug: { eq: "news" }) {
      childWordPressAcfTextHeader {
        smallHeading
        heading
        subHeading
      }
      childWordPressAcfFormBlock {
        smallHeading
        heading
        form
      }
    }
    allWordpressPost(
      sort: { fields: date, order: DESC }
      filter: { slug: { ne: "gatsby-build-post" } }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          featuredImage: featured_media {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1200, quality: 90) {
                  ... GatsbyImageSharpFluid
                }
              }
            }
          }
          title
          content
          excerpt
          date(formatString: "MMMM DD, YYYY")
          slug
        }
      }
    }
  }
`
